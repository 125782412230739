/*
  Created BY: Prasannadatta kawadkar
  Created On: 13 Dec 2022
  Creation: Toggle Button component Created
*/
import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { Home, Globe, Truck, Bell, Logout, Kanban, Person, PrintBill, Bill, Consignment, Profile, AccountBalance, Change, Company, Users, Hub, AccountBox } from '../../components/svgs';
import { route } from 'preact-router';
import { getItem, removeAll } from '../../lib/myStore';
import http from 'fetch-bb';
import CONSTANTS from '../../lib/constants';
import { Toast } from '../../lib/toastr';

const SideMenu = (props) => {

  // let [ selectedListItem, setSelectedListItem ] = useState('Home');
  // let [ hovered, setHovered ] = useState(false);
  let [ pathName, setPathName ] = useState([]);
  let [ isSiloAdmin, setIsSiloAdmin ] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({1: false, 2: false});
  let [ initialDisplayName, setInitialDisplayName ] = useState('');
  let [ userDisplayName, setUserDisplayName ] = useState('');
  let [ userEmail, setUserEmail ] = useState('');

  // const menuRef = useRef(null);

  /*
     Modified By: Manohar Sule
     Modified On: 29 Jan 2024
     Modification: When editing the Contact and refreshing the page, it navigates to the Home tab from the left navigation panel, but the Client Details view is displayed.
     Bug ID:ATC_BG_115
   */
   useEffect(() => {
     let userInfo = getItem('userinfo');
     console.log(userInfo,'-----userInfo', userInfo.displayName.split('')[0]);
     // userInfo.displayName.split('')[0]
     setInitialDisplayName(userInfo.displayName.split('')[0]);
     setUserDisplayName(userInfo.displayName)
     setUserEmail(userInfo.email)
   }, []);

  useEffect(() => {
    // onListItemSelect(window.location.pathname);
    let path = window.location.pathname.split('/');
    setPathName(path);
    console.log(path,'-----');
    let userInfo = getItem('userinfo');
    if (userInfo && userInfo.isSiloAdmin) setIsSiloAdmin(true);
  }, [window.location.pathname]);

  useEffect(() => {
    console.log(dropdownOpen,'useEffect dropdownOpen');
    setDropdownOpen(dropdownOpen)
  }, [dropdownOpen])



  // const handleClickOutside = (event) => {
  //       if (menuRef.current && !menuRef.current.contains(event.target)) {
  //           setDropdownOpen({});
  //       }
  //   };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

  const logout = async() => {
    try {
      await http.post(`${CONSTANTS.API_URL}/api/user/logout`);
      removeAll();
      new Toast('Successfully logged out', Toast.TYPE_DONE, Toast.TIME_LONG);
      route(`/`);
    }
    catch (HTTPException) {
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  };

  async function adminLogout() {
    try {
      // startLoader();
      await http.post(`${CONSTANTS.API_URL}/api/admin/logout`);
      // stopLoader();
      removeAll();
      new Toast('Successfully logged out', Toast.TYPE_DONE, Toast.TIME_LONG);
      route(`/admin/login`);
    } catch (HTTPException) {
      // stopLoader();
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  }

  const toggleDropdown = (event, index) => {
        event.preventDefault();
        setDropdownOpen(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const toggleDropdown1 = (index) => {
      // console.log(index,'indexxxxxxxxxx toggleDropdown1');
          // setDropdownOpen(prevState => ({
          //     ...prevState,
          //     [index]: !prevState[index]
          // }));
          let newDropDown = dropdownOpen;
          Object.keys(newDropDown).map((key) => {
            if (index === Number(key)) {

              // let newKey = Number(key);
              key = !newDropDown[Number(key)]
            } else {
              key = false;
            }
          })

          // const transformedKeysObject = Object.keys(dropdownOpen).reduce((acc, key) => {
          //   const newKey = Number(key);
          //   console.log(newKey,'newKey');
          //   if (index == newKey) {
          //     acc[newKey] = !dropdownOpen[key];
          //   } else {
          //     acc[newKey] = false;
          //
          //   }
          //   console.log(acc,'accccccccc');
          //   return acc;
          // }, {});

          // setDropdownOpen(prevState => ({
          //     ...prevState,
          //     [index]: !prevState[index]
          // }));

        // const transformedObject = Object.fromEntries(
        //   Object.entries(dropdownOpen).map(([key, value]) => {
        //     let newKey = Number(key);
        //     console.log(newKey,'newKey toggleDropdown1');
        //     if (newKey === index) {
        //       newKey = !value;
        //     }
        //     if (newKey !== index) {
        //       newKey = false;
        //     }
        //     return [newKey, value];
        //   })
        // );
        
        setDropdownOpen(newDropDown)
      // };
    }

    const useOutsideClick = (callback) => {
      const ref = useRef();
      useEffect(() => {
        const handleClick = (event) => {

          if (ref.current && !ref.current.contains(event.target)) {

            callback();
          }
        };
        document.addEventListener('click', handleClick, true);
        return () => {
          document.removeEventListener('click', handleClick, true);
        };
      }, [ref]);
      return ref;
    };

    const handleClickOutside = (event) => {

          toggleDropdown1(1)
      };
      const handleClickOutside1 = (event) => {

            toggleDropdown1(2)
        };

    const menuRef = useOutsideClick(handleClickOutside);
    const menuRefBill = useOutsideClick(handleClickOutside1);

  // const onListItemSelect = async(title) => {
  //   if (title === 'Home' || title === '/home') {
  //     route('/home');
  //     await setSelectedListItem('Home');
  //   } else if (title === 'Kanban' || title === '/KanbanRoute') {
  //     route('/KanbanRoute');
  //     await setSelectedListItem('Kanban' );
  //   } else if (title === 'Cashsheet' || title === '/cashSheet') {
  //     route('/cashSheet');
  //     await setSelectedListItem('Cashsheet' );
  //   } else if (title === 'Consignments' || title === '/consignments') {
  //     route('/consignments');
  //     await setSelectedListItem('Consignments' );
  //   } else if (title === 'Bills' || title === '/bills') {
  //     route('/bills');
  //     await setSelectedListItem('Bills' );
  //   } else if (title === 'Bill Generation' || title === '/billGeneration') {
  //     route('/billGeneration');
  //     await setSelectedListItem('Bill Generation' );
  //   } else if (title === 'Manifests' || title === '/manifest') {
  //     route('/manifest');
  //     await setSelectedListItem('Manifests' );
  //   } else if (title === 'Clients' || title === '/clients') {
  //     route('/clients');
  //     await setSelectedListItem('Clients' );
  //   } else if (title === 'Logout') {
  //     logout();
  //     await setSelectedListItem('Logout' );
  //   } else if (title === 'changePassword' || title === '/verifyOldPassword' || title === '/changePassword') {
  //     route('/verifyOldPassword');
  //     await setSelectedListItem('changePassword' );
  //   } else if (title === 'Report' || title === '/report') {
  //     route('/report');
  //     await setSelectedListItem('Report');
  //   } else if (title === 'Tracking' || title === '/tracking') {
  //     route('/tracking');
  //     await setSelectedListItem('Tracking');
  //   }
  // };

  // const addClassOnHover = async(title) => {
  //   await setHovered(title);
  // };
  //
  // const removeClassOnHover = async() => {
  //   await setHovered('');
  // };

  return (
    <div class="menu-wrapper" >
      <div class="p-8" style='height: 55px;'>
        <img src="../../assets/truck1.svg" class="company-logo"/> <span class="company-name">TRANS.PORT</span>
      </div>
      {
        !isSiloAdmin && (
          <div>
            <ul class="list-style" style=' padding: 5px;'>
              <li>
                <div class="profile-card">
                  <span class="profile-pic">{initialDisplayName}</span>
                  <div class="profile-info">
                      <p>{userDisplayName}</p>
                      <h2>{userEmail}</h2>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-style" style='overflow-y: auto;height: calc(100vh - 260px);'>
              <li class="list-item list-heading">
                  MAIN MENU
              </li>
              {/*
                Bug: 16	ATC_UI_BG_16	Admin	Authentication Logout button - Sign in screen	When clicking on the logout button a two toaster message is displayed.
                Modification: Removed duplicate onclick on div inside li
                By: Devang
                Date: 10/08/2023
              */}
              <li class={pathName.includes('home') ? 'list-item-selected' : 'list-item'} onclick={() => route('/home')}>
                <div id='Home'>
                  <Home height="20px" width="20px"/>
                  <span class="align-text-svg">Home</span>
                </div>
              </li>
              <li class={pathName.includes('KanbanRoute') ? 'list-item-selected' : 'list-item'} onclick={() => route('/KanbanRoute')} >
                <div>
                  <Kanban height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Kanban</span>
                </div>
              </li>
              {/*<li class={(pathName.includes('consignments') || pathName.includes('consignment')) ? 'list-item-selected' : 'list-item'} onclick={() => route('/consignments')}>
                <div>
                  <Consignment height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Consignments</span>
                </div>
              </li>*/}
              <li ref={menuRef} style='position: relative' class={(pathName.includes('consignments') || pathName.includes('consignment') || pathName.includes('warehouseConsignments')) ? 'list-item-selected' : 'list-item'} onClick={(e) => toggleDropdown(e, 1)} >
                <div>
                  <Consignment height="20px" width="20px"/>
                  <span class="align-text-svg font-bold dropdown-toggle"  >Consignments <span style='float: right; margin: 5px;'>{dropdownOpen[1] ? '▲' :  '▼' } </span></span>
                </div>
                <ul className={`dropdown-menu ${dropdownOpen[1] ? 'open' : ''}`}>
                    <li class={(pathName.includes('consignments')) ? 'list-item-active' : ''} onclick={() => route('/consignments')}>Consignments</li>
                    <li class={(pathName.includes('warehouseConsignments')) ? 'list-item-active' : ''} onclick={() => route('/warehouseConsignments')}>Warehouse</li>
                </ul>
              </li>
              <li class={(pathName.includes('clients') || pathName.includes('client')) ? 'list-item-selected' : 'list-item'} onclick={() => route('/clients')}>
                <div>
                  <Person height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Clients</span>
                </div>
              </li>
              <li class={pathName.includes('manifest') ? 'list-item-selected' : 'list-item'} onclick={() => route('/manifest')}>
                <div>
                  <Truck height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Manifests</span>
                </div>
              </li>
              <li class={pathName.includes('lorryChallan') ? 'list-item-selected' : 'list-item'} onclick={() => route('/lorryChallan')}>
                <div>
                  <Truck height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Lorry Challan</span>
                </div>
              </li>
              <li class={pathName.includes('billGeneration') ? 'list-item-selected' : 'list-item'} onclick={() => route('/billGeneration')}>
                <div>
                  <PrintBill height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Bill Generation</span>
                </div>
              </li>
              {/*<li class={pathName.includes('bills') ? 'list-item-selected' : 'list-item'} onclick={() => route("/bills")}>
                <div>
                  <Bill height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Bills</span>
                </div>
              </li>*/}

              <li ref={menuRefBill} style='position: relative' class={(pathName.includes('bills') || pathName.includes('writeOffBills')) ? 'list-item-selected' : 'list-item'} onClick={(e) => toggleDropdown(e, 2)} >
                <div>
                  <Bill height="20px" width="20px"/>
                  <span class="align-text-svg font-bold dropdown-toggle"  >Bills <span style='float: right; margin: 5px;'>{dropdownOpen[2] ? '▲' :  '▼' } </span></span>
                </div>
                <ul className={`dropdown-menu ${dropdownOpen[2] ? 'open' : ''}`}>
                    <li class={(pathName.includes('bills')) ? 'list-item-active' : ''} onclick={() => route('/bills')}>Bills</li>
                    <li class={(pathName.includes('writeOffBills')) ? 'list-item-active' : ''} onclick={() => route('/writeOffBills')}>Write off</li>
                </ul>
              </li>
              {/*
                BY: Prsannadatta Kawadkar
                ON: 08 Feb 2024
                Modification: commented tracking as it is not yet developed and comes in future work
              */}
              {/*<li class={pathName.includes('tracking') ? 'list-item-selected' : 'list-item'} onclick={() => route("/tracking")}>
                <div>
                  <Globe height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Tracking</span>
                </div>
              </li>*/}
              <li class={pathName.includes('cashSheet') ? 'list-item-selected' : 'list-item'} onclick={() => route("/cashSheet")}>
                <div>
                  <AccountBalance height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Cash Sheet</span>
                </div>
              </li>
              <li class={pathName.includes('report') ? 'list-item-selected' : 'list-item'} onclick={() => route("/report")}>
                <div>
                  <Consignment height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Report</span>
                </div>
              </li>
            </ul>
            <ul class="list-style" style="height: 120px;position: absolute;bottom: 0; left: 0; right: 0 ">
              <li class="list-item list-heading fs-12 font-bold">
                  GENERAL
              </li>
              <li class={pathName.includes('changePassword') ? 'list-item-selected' : 'list-item'} onclick={() => route("/changePassword")}>
                <div>
                  <Change height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Change Password</span>
                </div>
              </li>
              <li class={'list-item'} onclick={() => logout()}>
                <div>
                  <Logout height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Logout</span>
                </div>
              </li>
            </ul>
          </div>
        )
      }

      {
        isSiloAdmin && (
          <div>
            <ul class="list-style" style=' padding: 5px;'>
              <li>
                <div class="profile-card">
                  <span class="profile-pic">{initialDisplayName}</span>
                  <div class="profile-info">
                      <p>{userDisplayName}</p>
                      <h2>{userEmail}</h2>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-style" style='overflow-y: auto;height: calc(100vh - 260px);'>
              <li class="list-item list-heading">
                  ADMIN MENU
              </li>
              <li class={pathName.includes('companies') ? 'list-item-selected' : 'list-item'} onclick={() => route('/admin/companies')}>
                <div id='Companies'>
                  <Company height="20px" width="20px"/>
                  <span class="align-text-svg">Companies</span>
                </div>
              </li>
              <li class={pathName.includes('branches') ? 'list-item-selected' : 'list-item'} onclick={() => route('/admin/branches')}>
                <div id='Branches'>
                  <Hub height="20px" width="20px"/>
                  <span class="align-text-svg">Branches</span>
                </div>
              </li>
              <li class={pathName.includes('roles') ? 'list-item-selected' : 'list-item'} onclick={() => route('/admin/roles')}>
                <div id='Roles'>
                  <AccountBox height="20px" width="20px"/>
                  <span class="align-text-svg">Roles</span>
                </div>
              </li>
              <li class={pathName.includes('users') ? 'list-item-selected' : 'list-item'} onclick={() => route('/admin/users')}>
                <div id='Users'>
                  <Users height="20px" width="20px"/>
                  <span class="align-text-svg">Users</span>
                </div>
              </li>

            </ul>
            <ul class="list-style" style="height: 120px;position: absolute;bottom: 0; left: 0; right: 0 ">
              <li class="list-item list-heading fs-12 font-bold">
                  GENERAL
              </li>
              <li class={pathName.includes('changePassword') ? 'list-item-selected' : 'list-item'} onclick={() => route("/admin/changePassword")}>
                <div>
                  <Change height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Change Password</span>
                </div>
              </li>
              <li class={'list-item'} onclick={() => adminLogout()}>
                <div>
                  <Logout height="20px" width="20px"/>
                  <span class="align-text-svg font-bold">Logout</span>
                </div>
              </li>
            </ul>
          </div>

        )
      }


    </div>
  );
};

export default SideMenu;
